import React, { Component } from 'react'
import Layout from '../../components/layout';
import { Container, Form, FormGroup, Input, Label, ButtonGroup, Button } from 'reactstrap'
import { isLoggedIn } from '../../services/auth';
import { navigate } from '@reach/router';
import sha256 from 'sha256'
import moment from 'moment'
import { Banner } from '../../components/banner'



const jQuery = require('jquery')


// TODO: Finish API Testing Page
export default class apiQr extends Component {
    constructor (props) {
        super(props);
    
        if(typeof window !== "undefined"){
            window.$ = window.jQuery = require('jquery');
        }

        this.state = { 
            window: 'current',
            mode : 'uat',
            mid : "11137066800",
            tid: "37066801",
            stan: "100001",
            apiKey : "231e4c11-135a-4457-bc84-3cc6d3565506",
            secretKey : "16c573bf-0721-478a-8635-38e53e3badf1",
            amount : "1000",

            payloadReqContent : "",
            payloadReqSign : "",

            status : false
        }
    
        this.onRadioBtnClick = this.onRadioBtnClick.bind(this)
    }

    componentDidMount() {
        if(!isLoggedIn()){
            window.location.replace('/signin/?referrer=' + encodeURIComponent(this.props.location.pathname))
        }
    }
    
    onRadioBtnClick(val, type) {
        if(type === 'window'){
            this.setState({ window : val })
        } else if(type === 'mode'){
            this.setState({ mode : val })
        }

    }

    handleChange = (event) => {
        const name = event.target.id
        const value = event.target.value

        this.setState({
            [name] : value
        })
    }

    async submitHandler(){
        
        this.setState({
            status : true
        })
        let mode = this.state.mode
        var qrCode = '';
        let amount = this.state.amount
        let stan = this.state.stan
        let tid = this.state.tid
        let mid = this.state.mid
        let apiKey = this.state.apiKey
        let apiSecret = this.state.secretKey
        var urlOrder = '';
        var urlQuery = '';
        var transaction_time = moment().format('hhmmss');
        var transaction_date = moment().format('MMDD');

        if (mode === 'uat') {
            urlOrder = 'https://uat-api.nets.com.sg:9065/uat/merchantservices/qr/dynamic/v1/order/request';
            urlQuery = "https://uat-api.nets.com.sg:9065/uat/merchantservices/qr/dynamic/v1/transaction/query";
        } else if (mode === "prod") {
            urlOrder = 'https://api.nets.com.sg/merchantservices/qr/dynamic/v1/order/request';
            urlQuery = "https://api.nets.com.sg/merchantservices/qr/dynamic/v1/transaction/query";
        } 


        var data = JSON.stringify({"mti":"0200","process_code":"990000","amount":amount,"stan":stan,"transaction_time":transaction_time,"transaction_date":transaction_date,"entry_mode":"000","condition_code":"85","institution_code":"20000000001","host_tid":tid,"host_mid":mid,"npx_data":{"E103":tid,"E201":"00000123","E202":"SGD"},"communication_data":[{"type":"http","category":"URL","destination":"https://your-domain-name:8801/demo/order/notification","addon":{"external_API_keyID":"8bc63cde-2647-4a78-ac75-d5f534b56047"}}],"getQRCode":"Y"});

        console.log(data)

        var sign = btoa(sha256(data + apiSecret).match(/\w{2}/g).map(function (a) {
            return String.fromCharCode(parseInt(a, 16));
        }).join(''));

        // Return the order Payload
        this.setState({
            payloadReqContent : data,
            payloadReqSign : sign
        })

        jQuery.ajax({
            type: 'POST',
            url: urlOrder,
            async: false,
            process_data: 'false',
            headers: {
                'KeyId': apiKey,
                'Sign': sign,
                'Content-Type': 'application/json'
            },
            data: data,
            dataType: 'text',
            success: function(result) {
                console.log(result);
                var json_result = jQuery.parseJSON(result)
                qrCode = json_result.txn_identifier;
                jQuery('#payment_image').html('<img id="netspay-mobile-link" class="hidden-xs" height="180" width="180" src="data:image/png;base64,' + json_result.qr_code + '" /> <a href="netspay://payment/?source=com.nets.netspay&amp;urlscheme=netspay&amp;qrdata=' + encodeURIComponent(qrCode) + '"> <img class="hidden-md hidden-lg height="180" width="180" " src="https://uat2.enets.sg/demo/assets/images/netspay.png" /></a>');
                
                setTimeout(function() {
                    query_function();
                }, 3000);

                jQuery("#netspay-mobile-link").on('load', function() {
                    jQuery('html,body').animate({
                    scrollTop: jQuery("#payment_image").offset().top},
                    '2500');
                });
            },
            error: function(result) {
                console.log(result);
            }
        });

        var inquiryCount = 1;

        var data2 = JSON.stringify({"mti":"0100","process_code":"330000","stan":stan,"transaction_time":transaction_time,"transaction_date":transaction_date,"entry_mode":"000","condition_code":"85","institution_code":"20000000001","host_tid":tid,"host_mid":mid,"npx_data":{"E103":tid,"E201":"00000123","E202":"SGD"},"txn_identifier":qrCode});

        console.log(data2);

        sign = btoa(sha256(data2 + apiSecret).match(/\w{2}/g).map(function (a) {
            return String.fromCharCode(parseInt(a, 16));
        }).join(''));

        let query_function = (function() {
            jQuery.ajax({
                type: "POST",
                async: false,
                process_data: 'false',
                headers: {
                'KeyId': apiKey,
                'Sign': sign,
                'Content-Type': 'application/json'
                },
                url: urlQuery,
                data: data2,
                success: function(result) {
                console.log(result);
                if (result.response_code === '09' && inquiryCount < 20) {
                    setTimeout(function() {
                    query_function();
                    }, 2500);
                    inquiryCount++;
                } else {
                    jQuery('#payment_image').hide();
                    
                    if (result.response_code === '00') {
                        jQuery('#result_image').html('<img src="https://www.svgrepo.com/show/13650/success.svg" alt="Transaction Successful" height="100" width="100"><h4>Payment Success!</h4>');
                    } else {
                        jQuery('#result_image').html('<img src="https://www.svgrepo.com/show/13658/error.svg" alt="Transaction Failure" height="100" width="100"><h4>Payment Failure!</h4>');
                    }
                }
                },
                error: function(result) {
                    console.log(result);
                }
            });
        });
        jQuery(this).hide();
    }

    // TODO: Create Submit handler for QR Demo
    render() {
        if(isLoggedIn()){
            return (
                <Layout>
                    <Banner title={`NETS QR Demo`} />
                    <Container
                        style={{
                        padding: '2rem 2rem 1rem',
                        minHeight: '75vh'
                        }}
                    >
                        <Form>
                            <div className="data-input">
                                <table className="table table-condensed" align="center">
                                    <thead>
                                    <tr>
                                        <th>Fields</th>
                                        <th>Input</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <Label>Amount (Cents) </Label>
                                            </td>
                                            <td>
                                                <FormGroup>
                                                        
                                                    <Input 
                                                        type="number"
                                                        size="40"
                                                        id="amount"
                                                        defaultValue="1000"
                                                        onChange={this.handleChange}
                                                    />
                                                </FormGroup>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <Label>STAN</Label>
                                            </td>
                                            <td>
                                                <FormGroup>
                                                        
                                                    <Input 
                                                        type="text"
                                                        id="stan"
                                                        defaultValue="100001"
                                                        onChange={this.handleChange}
                                                        size="40"
                                                    />
                                                </FormGroup>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <Label>Host TID</Label>
                                            </td>
                                            <td>
                                                <FormGroup>
                                                        
                                                    <Input 
                                                        type="text"
                                                        id="tid"
                                                        defaultValue="37066801"
                                                        onChange={this.handleChange}
                                                    />
                                                </FormGroup>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <Label>Host MID</Label>
                                            </td>
                                            <td>
                                                <FormGroup>
                                                        
                                                    <Input 
                                                        type="text"
                                                        id="mid"
                                                        defaultValue="11137066800"
                                                        onChange={this.handleChange}
                                                    />
                                                </FormGroup>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <Label>API Key</Label>
                                            </td>
                                            <td>
                                                <FormGroup>
                                                        
                                                    <Input 
                                                        type="text"
                                                        id="apiKey"
                                                        defaultValue="231e4c11-135a-4457-bc84-3cc6d3565506"
                                                        onChange={this.handleChange}
                                                    />
                                                </FormGroup>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <Label>Secret Key</Label>
                                            </td>
                                            <td>
                                                <FormGroup>
                                                        
                                                    <Input 
                                                        type="text"
                                                        id="secretKey"
                                                        defaultValue="16c573bf-0721-478a-8635-38e53e3badf1"
                                                        onChange={this.handleChange}
                                                    />
                                                </FormGroup>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <Label>Mode</Label>
                                            </td>
                                            <td>
                                                <ButtonGroup>
                                                    <Button 
                                                        color={this.state.mode === 'uat' ? 'primary' : 'secondary'} 
                                                        onClick={event => {
                                                            event.preventDefault()
                                                            this.onRadioBtnClick('uat', 'mode')
                                                        }}
                                                        active={this.state.mode === 'uat'}
                                                    >
                                                        UAT
                                                    </Button>
                                                </ButtonGroup>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            
                            <Container
                                className="text-right"
                                style={{
                                    margin : `0.875rem 0`
                                }}
                            >
                                <Button
                                    color="link"
                                    onClick={event => {
                                        event.preventDefault()
                                        navigate('/docs/reference')
                                    }}
                                >
                                    Cancel
                                </Button>

                                <Button
                                    color="primary"
                                    onClick={ e => {
                                        e.preventDefault()
                                        this.submitHandler()
                                    }}
                                >
                                    Send Payload
                                </Button>
                            </Container>
                        </Form>

                            

                        <div id="payment_image"></div>
                        <div id="result_image"></div>
                        <hr />
                        {this.state.payloadReqContent ? (
                            <div>
                                {/* Request : Payload */}
                                <h3>[REQUEST] Payload</h3>
                                <pre style={{
                                    whiteSpace: `pre-wrap`,
                                    textAlign: `justify`,
                                    wordBreak: `break-all`,
                                    padding : `5px 10px`
                                }}>
                                    {this.state.payloadReqContent}
                                </pre>

                                {/* Request : Payload Hash */}
                                <h3>[REQUEST] Payload Hash</h3>
                                <pre style={{
                                    whiteSpace: `pre-wrap`,
                                    textAlign: `left`,
                                    wordBreak: `break-all`,
                                    padding : `5px 10px`
                                }}>
                                    {this.state.payloadReqSign}
                                </pre>
                            </div>
                        ) : (
                            null
                        )}
                        <p style={{textAlign: `center`}}>UAT simulater (Credit & Debit) is available daily from 0900H to 1600H (GMT+8)<br /><a href={`/support/schedule`} >Maintenance Schedule</a></p>
                </Container>
            </Layout>
            )
        } else {
            return ''
        }
    }
}
